import React, { useState } from "react";

const Contact = () => {
  const [message, setMessage] = useState("");

  //javascript para abrir whatsapp
  const openWhatsApp = () => {
    const encodedMessage = encodeURIComponent(message);
    const url = `https://api.whatsapp.com/send?phone=5511920859859&text=${encodedMessage}`;
    window.open(url, "_blank");
  }

  return (
    <div id='contact' className="contact-page-wrapper">
      <h1 className="primary-heading">Quer conversar conosco?</h1>
      <h1 className="primary-heading">Chama a gente no whatsapp</h1>
      <div className="contact-form-container">
        <input type="text" placeholder="sua mensagem" value={message} onChange={(e) => setMessage(e.target.value)}/>
        <button className="secondary-button" onClick={() => openWhatsApp() }>Abrir Whats</button>
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div id='about' className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Sobre</p>
        <h1 className="primary-heading">
          Você é o que você come!
        </h1>
        <p className="primary-text">
          Somos produtores rurais apaixonados por oferecer alimentos frescos, saudáveis e saborosos diretamente da nossa família para a sua mesa.
        </p>
        <p className="primary-text">
          Acreditamos em uma forma de consumo mais consciente, que valoriza a proximidade entre o produtor e o consumidor, sem intermediários.
        </p>
        <p className="primary-text">
          Aqui você encontra produtos cultivados com carinho e respeito à natureza, priorizando práticas sustentáveis que preservam o meio ambiente e garantem a qualidade dos alimentos.
        </p>
        <p className="primary-text">Trabalhamos em pequena escala, com foco na qualidade e no sabor, e nos orgulhamos de conhecer cada etapa do processo, desde o plantio até a entrega.

        </p>
        <p className="primary-text">
          Além disso, nos dedicamos a apoiar outros pequenos produtores da região, comprando seus produtos a preço justo e oferecendo-os em nossa plataforma. Dessa forma, contribuímos para o desenvolvimento local e para a construção de uma comunidade mais justa e sustentável.
        </p>
        <p className="primary-text">
          Mais do que um mercado online, queremos criar laços de amizade e confiança com nossos clientes. Acreditamos que a comida tem o poder de conectar as pessoas e construir relações verdadeiras. Por isso, estamos sempre buscando oferecer um atendimento personalizado, com atenção aos detalhes e cuidado em cada entrega.
        </p>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-icons">
          <BsInstagram onClick={() => window.open("https://www.instagram.com/verdemusgo.natural", "_blank")} />
          <BsYoutube onClick={() => window.open("https://www.youtube.com/@verdemusgonatural", "_blank")} />
          <FaFacebookF onClick={() => window.open("https://www.facebook.com/profile.php?id=100086427699025", "_blank")} />
        </div>
      </div>
        <div className="footer-section-columns">
          <span><a href="https://loja.verdemusgonatural.com.br">Acesse a nossa loja</a></span>
          <span><a href="https://track.verdemusgonatural.com.br">Rastreie nossa produção (em construção)</a></span>
          <span><a href="https://blog.verdemusgonatural.com.br">Acesse nossas receitas (em construção)</a></span>
        </div>
    </div>
  );
};

export default Footer;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import Logo from "../Assets/Logo.png";
import { Link } from "react-scroll";
import { CardMembership } from "@mui/icons-material";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Principal",
      icon: <HomeIcon />,
      to: "main",
    },
    {
      text: "Sobre",
      icon: <InfoIcon />,
      to: "about",
    },
    {
      text: "Certificate",
      icon: <CardMembership />,
      to: "certificate",
    },
    {
      text: "Saiba mais",
      icon: <PhoneRoundedIcon />,
      to: "contact",
    },
    {
      text: "Loja",
      icon: <ShoppingCartRoundedIcon />,
      to: "https://loja.verdemusgonatural.com.br",
      type: "href"
    },
  ];
  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" style={{ marginTop: "5px" }} />
      </div>
      <div className="navbar-links-container">
        <Link to="main"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={800}>
          Principal</Link>
        <Link to="about"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={800}>
          Sobre
        </Link>
        <Link to="certificate"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={800}>
          Certificado
        </Link>
        <Link to="contact"
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={800}>
          Contato</Link>
        <button className="primary-button" onClick={() => window.open("https://loja.verdemusgonatural.com.br", "_blank")}>Nossa loja
        </button>
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  {item.type === "href" ? (<a href={item.to}>{item.text}</a>) :
                    <Link to={item.to}
                      activeClass="active"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={800}>
                      {item.text}
                    </Link>
                  }
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
import React from "react";
import CertificateImage from "../Assets/certificadotransicaoano3.png";

const Certificate = () => {

  return (
    <div id="certificate" className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Certificado</p>
        <div className="work-section-bottom">
          <div className="info-boxes-img-container">
            <img src={CertificateImage} alt="" />
          </div>
        </div>
        <p className="primary-text">
          Este é o certificado de transição agroecológica que o governo do estado de São Paulo nos fornece e que atesta que nossa propriedade está em conformidade com as práticas de produção sustentável e de proteção à natureza.
        </p>
        <p className="primary-text">
          É como se fosse uma certificação de orgânico. Na verdade é ainda mais abrangente, porque abrange não só o manejo da hortaliça mas o manejo da própriedade, a proteção ao ambiente, às nascentes, à fauna e flora, coisa que o certificado de orgânico não se preocupa.
        </p>
        <p className="primary-text">
          Estamos no terceiro ano do processo de transição que leva cinco anos ao total. Estamos muito orgulhosos do trabalho que estamos fazendo.
        </p>
      </div>
    </div>
  );
};

export default Certificate;
